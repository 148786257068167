import React from 'react';
import { Link } from 'gatsby';
import { Icon } from '@iconify/react';
import openInNew from '@iconify/icons-mdi/open-in-new';

export default function ProjectCard({ item }) {
  return (
    <div className="overflow-hidden cursor-pointer hover:scale-105 transition-transform duration-300">
      <Link to={item.link} target={item.isExternal ? '_blank' : ''}>
        <div className="relative overflow-hidden rounded-2xl">
          <img placeholder="blurred" src={item.cover} loading="lazy" alt={item.title} />
          <div className="absolute hidden md:block transition-opacity duration-300 top-0 left-0 right-0 bottom-0">
            <div className="absolute bottom-0 text-center w-full p-2 md:p-4 bg-[#0000008f] text-white">
              <span className="font-bold text-normal">{item.title}{item.isExternal && <Icon className="mx-2 inline" icon={openInNew} />}</span>
            </div>
          </div>
          <div className="md:hidden text-sm py-2">
            {item.title}
          </div>
        </div>
      </Link>
    </div>
  );
}